import * as React from "react";
import { forwardRef, useEffect, useState } from "react";
import ClickToEdit from "react-click-to-edit";

const roleMapping = {
  delegate: "Delegate",
  exhibitor: "Exhibitor",
  speaker: "Speaker",
  superadmin: "Organiser",
  "vip/faculty": "VIP / Faculty",
};

export const PrintContent = React.forwardRef<HTMLDivElement, any>(
  (props, ref) => {
    const { user, setEditMode } = props;
    const { fullName, qrLabel, placeOfPractice, qrCode } = user;

    const renderField = (value, className = "") => (
      <ClickToEdit
        wrapperClass={`line ${className}`}
        initialValue={value}
        startEditing={() => {
          setEditMode(true);
        }}
        endEditing={() => {
          setEditMode(false);
        }}
      />
    );

    return (
      <div ref={ref} className="print-page">
        <style type="text/css" media="print">
          {`
            @page { 
              size: 4in 6in;
              margin: 2.51in 0.21in 0 0.21in;
            }
            .print-page {
              box-sizing: border-box;
            }
            .line {
              display: block !important;
              text-align: center;
              line-height: 1.3;
              color: black;
            }
            .fullName {
              font-family: 'Arial Black', 'Arial', sans-serif;
              font-size: 18pt;
              font-weight: 900;
              text-align: center;
              margin-bottom: 8pt;
            }
            .placeOfPractice {
              font-family: 'Arial Bold', 'Arial', sans-serif;
              font-size: 16pt;
              font-weight: 700;
              text-align: center;
              margin-bottom: 0;
            }
            .CTE--wrapper:after {
              content: '' !important;
            }
            #qrLabel {
              display: block;
              width: 135px;
              margin: 0 auto;
            }
            .qrCode {
             font-family: 'Arial', sans-serif;
              font-size: 6pt;
              font-weight: 400;
              text-align: center;
              margin: 0;
            }
          `}
        </style>
        {/* <div className="imageGap"></div> */}
        {renderField(fullName, "fullName")}
        {renderField(placeOfPractice, "placeOfPractice")}

        <img id="qrLabel" src={qrLabel} />
        {renderField(qrCode, "qrCode")}
      </div>
    );
  }
);

// const PrintContent = forwardRef(PrintContentRef)
export default PrintContent;
