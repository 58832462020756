import * as React from "react";

const AccessRight = ({ user }) => {
  const renderFlag = (bool) =>
    bool ? (
      <i className="fas fa-check" style={{ color: "green" }}></i>
    ) : (
      <i className="fas fa-times" style={{ color: "red" }}></i>
    );

  return (
    <>
      <div className="mt-3">User Details:</div>
      <div className="card mt-2 p-4 w-100">
        <div className="mb-2">
          <span className="fw-bold">Conference Pass:&nbsp;</span>
          {renderFlag(user.eventAccess)}
        </div>

        <div className="mb-2">
          <div>ID:</div>
          <div className="fw-bold">{user.id}</div>
        </div>

        <div className="mb-2">
          <div>Email:</div>
          <div className="fw-bold">{user.email}</div>
        </div>

        <div className="mb-2">
          <div>Full Name:</div>
          <div className="fw-bold">{user.fullName}</div>
        </div>
      </div>
    </>
  );
};

export default AccessRight;
